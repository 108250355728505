<!--
 * @Author: 马妍
 * @Date: 2023-12-20 13:47:18
 * @LastEditors: 马妍
 * @LastEditTime: 2024-01-01 19:59:06
 * @Description: 
-->
<template>
  <div id="app">
    <Home />
    <footer>
      <div>
        网站备案编号：
        <a href="https://beian.miit.gov.cn/" target="_blank"
          >宁ICP备2024002890号-1</a
        >
      </div>
    </footer>
  </div>
</template>

<script>
import Home from "./Home/index";
export default {
  name: "App",
  components: {
    Home,
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
html,
body,
#app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #f3f3f3;
}
#app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
footer {
  font-size: 12px;
  color: #666;
}
</style>
