<template>
  <div class="AppBox">
    <!-- scoket -->
    <el-container v-if="isLogin">
      <el-header>
        <el-avatar>{{ userName }}</el-avatar>
        &emsp;
        <div>{{ userName }}</div></el-header
      >
      <el-main>
        <div
          v-for="item in messages"
          :key="item.socketId"
          :class="item.socketId === socketId ? 'right item' : 'left item'"
        >
          <template v-if="item.socketId === socketId">
            <div class="message">
              <div class="content">
                {{ item.message }}
              </div>
            </div>
            <div>
              <el-avatar>{{ item.name }}</el-avatar>
            </div>
          </template>
          <template v-else>
            <div>
              <el-avatar>{{ item.name }}</el-avatar>
            </div>
            <div class="message">
              <div class="content">
                {{ item.message }}
              </div>
            </div>
          </template>
        </div>
      </el-main>
      <el-footer>
        <el-input
          style="width: 100px; margin-right: 10px"
          v-model="newMessage"
          placeholder="请输入"
          @keyup.enter.native="sendMessage()"
        />
        <el-button type="success" @click="sendMessage()">发送</el-button>
      </el-footer>
    </el-container>
    <!-- 登录 -->
    <el-form label-width="80px" :model="form" style="width: 500px" v-else>
      <el-form-item label="名称">
        <el-input v-model="form.username"></el-input>
      </el-form-item>
      <el-form-item label="密码">
        <el-input v-model="form.password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="login">登 录</el-button>
        <el-button @click="register">注 册</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import io from "socket.io-client";
import axios from "axios";

export default {
  data() {
    return {
      isLogin: false,
      socket: null,
      messages: [],
      userName: "",
      newMessage: "",
      socketId: "",
      form: {
        username: "",
        password: "",
      },
    };
  },
  created() {
    axios.get("/api/userInfo").then((res) => {
      if (res.data.code === 200) {
        this.userName = res.data.userInfo.username;
        this.isLogin = true;
      } else {
        this.$message(res.data.message);
        this.isLogin = false;
      }
    });
  },
  mounted() {
    // 连接到Socket.IO服务器
    this.socket = io("https://lymy52.cn/socket", {
      transports: ["websocket"],
    });

    //连接成功事件
    this.socket.on("connect", () => {
      console.log("服务端ws连接成功", this.socket);
      this.socketId = this.socket.id;
      this.socket.on(this.socket.id, this.receiveMessage); // 接收服务推送的消息
      this.socket.on("message", this.receiveMessage); // 接收服务推送的消息
    });

    this.socket.on("disconnecting", (data) => {
      console.log("正在断开连接", data);
    });

    this.socket.on("disconnect", (data) => {
      console.log("连接已断开", data);
    });

    this.socket.on("error", (data) => {
      console.log("error", data);
    });
  },
  methods: {
    login() {
      if (this.form.username === "" || this.form.password === "") {
        return this.$message.error("用户名或密码不能为空");
      }
      axios.post("/api/login", this.form).then((res) => {
        if (res.data.code === 200) {
          this.isLogin = true;
          this.userName = res.data.data.username;
          this.$message.success("登录成功");
        } else {
          this.isLogin = false;
          this.$message.error(res.data.message);
        }
      });
    },
    register() {
      if (this.form.username === "" || this.form.password === "") {
        return this.$message.error("用户名或密码不能为空");
      }
      axios.post("/api/register", this.form).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("注册成功");
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    receiveMessage(message) {
      const content = JSON.parse(message || "{}");
      console.log("收到消息", content);
      this.messages.push(content);
    },
    sendMessage() {
      if (!this.newMessage) return;
      const content = JSON.stringify({
        name: this.userName,
        message: this.newMessage,
        socketId: this.socketId,
      });
      console.log("发送消息", content);
      this.messages.push(JSON.parse(content));
      this.socket.emit("msg", content);
      this.newMessage = "";
    },
  },
};
</script>
<style scoped>
.AppBox {
  width: 70%;
  height: 70%;
  display: flex;
  justify-content: center;
  text-align: center;
}
section {
  width: initial;
  height: inherit;
  display: flex;
}
header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
main {
  flex: 1;
}
.item {
  display: flex;
  margin-bottom: 10px;
}
.message {
  flex: 1 0;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.content {
  max-width: 50%;
  padding: 10px;
  border-radius: 4px;
  word-break: break-word;
}
.left .content {
  background: #fff;
}
.right .content {
  background: #a9ea7a;
}
.left .message {
  margin-left: 6px;
}
.right .message {
  margin-right: 6px;
  justify-content: flex-end;
}

.el-avatar {
  flex-shrink: 0;
}
footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-input {
  flex: 1;
}
</style>

